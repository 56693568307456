import React, { Component } from "react";
import {
  Button,
  DropdownButton,
  FormGroup,
  FormControl,
  Table,
  Modal,
} from "react-bootstrap";
const { Body, Title, Header, Footer } = Modal;
import moment from "moment";
import Pagination from "react-js-pagination";
import ReactTooltip from "react-tooltip";

import * as indexDb from "../../db/recipesDOA";
import BoxTrans from "./boxtrans.js";
import BoxWizard from "./boxwizard.js";
import images from "../images";
import SettingsStyle from "../../styles/settingsstyle";
import ConfirmDeleteModal from "../utils/confirmDeleteModal";
import { hours, minutes } from "../../data/time";

export default class Transformers extends Component {
  constructor() {
    super();
    this.state = {
      environ: null,
      orderAlpha: false,
      orderType: false,
      searchTerm: "",
      environDropDownOpened: false,
      environDropDownForceOpen: false,
      rowsPerPageDropDownOpened: false,
      rowPerPageDownForceOpen: false,
      selectedForDelete: null,
      isDeleting: null,
      modal: false,
      sortType: 0,
      showWiz: true,
      showRec: true,
      isListView: true,
      currentRecipeId: 0,
      showPropertiesModal: false,
      name: "",
      description: "",
      threasholdHours: "",
      threasholdMinutes: "",
      tag: "",
      processingType: "batch",
      recipeEdit: false,
      modeCheck: false,
      activePage: 1,
      recordsPerPage: 20,
      loading: false,
      recipeList: [],
      totalItemsCount: 0,
      notificationEmail: {},
      emailText: "",
      emailValidation: "",
    };
    this.newTransformer = this.newTransformer.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRowSizeChange = this.handleRowSizeChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.sortAlphabetically = this.sortAlphabetically.bind(this);
    this.sortTimeWise = this.sortTimeWise.bind(this);
    this.sortType = this.sortType.bind(this);
    this.sortTrans = this.sortTrans.bind(this);

    this.selectEnviron = this.selectEnviron.bind(this);
    this.selectRowsPerPage = this.selectRowsPerPage.bind(this);
    this.onToggleEnviron = this.onToggleEnviron.bind(this);
    this.onToggleRowsPerPage = this.onToggleRowsPerPage.bind(this);

    this.deleteRecipe = this.deleteRecipe.bind(this);
    this.handleSelectEdit = this.handleSelectEdit.bind(this);
    this.warnBeforeDelete = this.warnBeforeDelete.bind(this);
    this.triggerModal = this.triggerModal.bind(this);
    this.addEmail = this.addEmail.bind(this);
    this.deletEmail = this.deletEmail.bind(this);
    this.getThreasholdHours = this.getThreasholdHours.bind(this);
    this.getThreasholdMinutes = this.getThreasholdMinutes.bind(this);
  }

  onToggleEnviron(v) {
    window.setTimeout(() => {
      if (!this.state.environDropDownForceOpen) {
        this.setState({ environDropDownOpened: v });
      }
    }, 10);
  }

  onToggleRowsPerPage(v) {
    window.setTimeout(() => {
      if (!this.state.rowPerPageDownForceOpen) {
        this.setState({ rowsPerPageDropDownOpened: v });
      }
    }, 10);
  }

  componentWillMount() {
    const { actions } = this.props;
    const { appId } = this.props.match.params;
    this.handlePageChange(this.state.activePage); // load view
    actions.callGetTransformers(appId);
  }

  changeName = (e) => {
    const searchTerm = e;
    this.setState({
      searchTerm,
      activePage: 1,
    });
  };

  componentDidUpdate() {
    const { actions } = this.props;
    const { appId } = this.props.match.params;
    const { transformer } = this.props.redux;

    if (this.state.currentRecipeId > 0 && !transformer.versions) {
      actions.callGetTransformerVersions(appId, this.state.currentRecipeId);
    }
  }

  handlePageChange(pageNumber) {
    this.setState({
      activePage: pageNumber,
      loading: false,
    });
  }

  handlePropertiesChange(r) {
    const { actions } = this.props;
    const { transformer } = this.props.redux;

    actions.callGetTransformerVersions(r.appId, r.BaseId);
    actions.callGetTransformersEmails(r.BaseId);

    if (r) {
      const threasholdHours = this.getThreasholdHours(r.maxDataProcTime);
      const threasholdMinutes = this.getThreasholdMinutes(r.maxDataProcTime);

      this.setState({
        currentRecipeId: r.BaseId,
        showPropertiesModal: true,
        name: r.Name,
        description: r.Description,
        threasholdHours: threasholdHours,
        threasholdMinutes: threasholdMinutes,
        notificationEmail: transformer.notificationEmail.emails,
        tag: r.Environ,
        processingType: r.processingType,
        recipeEdit: false,
        modeCheck: r.processingType == "stateless" ? true : false,
      });
    }
  }

  getThreasholdHours(date) {
    if (date) {
      const time = date.split("T")[1];
      const hours = time.split(":")[0];
      return hours;
    } else return "00";
  }

  getThreasholdMinutes(date) {
    if (date) {
      const time = date.split("T")[1];
      const minutes = time.split(":")[1];
      return minutes;
    } else return "00";
  }

  addEmail() {
    const { actions } = this.props;
    const email = this.state.emailText;

    actions.addNotificationEmail(email);
    this.setState({ emailText: "" });
  }

  deletEmail(id) {
    const { actions } = this.props;
    actions.deleteNotificationEmail(id);
  }

  changeDescriptionInput = (e) => {
    this.setState({ description: e.target.value });
  };

  saveTransformer(toUpdate, transId) {
    if (toUpdate != "name")
      this.setState({ showPropertiesModal: false, currentRecipeId: 0 });
    const { transformer, project } = this.props.redux;
    const { appId } = this.props.match.params;
    const str = this.state.name;
    let a = str.substring(str.length - 3);
    let name;
    if (a != ".lr") {
      name = this.state.name + ".lr";
    } else {
      name = this.state.name;
    }

    this.props.actions
      .callUpdateTransformerInfo(
        {
          name: name,
          config: transformer.versions[0].Config,
          environ: this.state.tag,
          version: transformer.versions[0].Version,
          baseid: transformer.versions[0].BaseId,
          id: transformer.versions[0].Id,
          description: this.state.description,
          languageVersion: transformer.versions[0].LanguageVersion || "2.0",
          processingType: this.state.processingType,
          maxDataProcTime: `0001-01-01T${this.state.threasholdHours}:${this.state.threasholdMinutes}:00Z`,
        },
        appId,
        transId
      )
      .then(() =>
        this.props.actions.callUpdateTransformerEmailList(
          {
            emails: transformer.notificationEmail.emails,
          },
          transId
        )
      )
      .then(() => {
        indexDb
          .UpdateRecipeName(
            transformer.versions[0].BaseId,
            name,
            this.state.tag,
            this.state.description,
            this.state.processingType
          )
          .then(() => {
            this.setState({
              recipeToEdit: null,
              name: "",
              recipeRename: false,
            });
            indexDb.GetAllRecipes(project.appData.Id).then((recipes) => {
              this.setState({ indexedRecipes: recipes });
            });
          });
      });
  }

  handleChange = (modeCheck) => {
    if (modeCheck) this.setState({ processingType: "stateless" });
    else this.setState({ processingType: "batch" });
    this.setState({ modeCheck });
  };

  selectEnviron() {
    this.setState({ environDropDownForceOpen: true });
    window.setTimeout(() => {
      this.setState({ environDropDownForceOpen: false });
    }, 100);
  }

  selectRowsPerPage() {
    this.setState({ rowPerPageDownForceOpen: true });
    window.setTimeout(() => {
      this.setState({ rowPerPageDownForceOpen: false });
    }, 100);
  }

  handleRowSizeChange(e) {
    this.setState({ recordsPerPage: parseInt(e.target.value) });
    this.handlePageChange(1);
  }

  handleClick(event) {
    if (!event.target.checked || event.target.value === "ALL") {
      this.setState({ environ: null, showWiz: true });
    } else {
      this.setState({ environ: event.target.value, showWiz: false });
    }
  }
  newTransformer() {
    this.props.history.push({
      pathname: "/a/" + this.props.match.params.appId + "/tf/new",
    });
  }

  sortTrans() {
    let { sortType } = this.state;
    sortType++;
    if (sortType > 1) {
      sortType = 0;
    }
    this.setState({ sortType });
  }

  sortAlphabetically() {
    this.setState({ orderAlpha: true, orderType: false });
  }

  sortTimeWise() {
    this.setState({ orderAlpha: false, orderType: false });
  }

  sortType() {
    this.setState({ orderType: true });
  }

  changeTagInput = (e) => {
    this.setState({ tag: e.target.value });
  };

  handleSelectEdit(a, e, c) {
    c.stopPropagation();
    const { appId } = this.props.match.params;
    this.props.history.push(`/a/${appId}/tf/${e}/info`);
  }

  warnBeforeDelete(a, e, c) {
    c.stopPropagation();
    this.setState({ selectedForDelete: e });
    this.triggerModal();
  }

  triggerModal() {
    let modal = this.state.modal;
    modal = !modal;
    this.setState({ modal });
  }

  userDetails(userId) {
    const { main } = this.props.redux;
    const user = main.users.filter((u) => {
      return u.id == userId;
    });

    // when user is null, undefined or []
    return user && user.length > 0 ? user[0].username : "";
  }

  deleteRecipe() {
    const { appId } = this.props.match.params;
    const { project } = this.props.redux;
    const target =
      this.state.selectedForDelete && this.state.selectedForDelete.BaseId;
    const piGuid =
      this.state.selectedForDelete &&
      this.state.selectedForDelete.ProductizedIntegrationGuid;

    if (piGuid) {
      this.props.actions.callDeleteWizard(
        project.tenantInfo.TenantId,
        appId,
        piGuid
      );
    } else {
      this.props.actions.callDeleteTransformer(appId, target);
    }

    this.triggerModal();
    this.setState({ selectedForDelete: null, isDeleting: target });
  }

  click(appId, recipeId) {
    this.props.history.push({
      pathname: `/a/${appId}/tf/${recipeId}`,
    });
  }

  render() {
    const { project, transformer, main } = this.props.redux;
    const {
      showWiz,
      showRec,
      isListView,
      loading,
      activePage,
      recordsPerPage,
    } = this.state;
    let transRows = [];
    const environs = {};
    if (project.transformers && project.transformers.length > 0) {
      if (!isListView) {
        const objs = [...project.transformers];
        for (let i = 0; i < objs.length; i++) {
          if (!this.state.environ || objs[i].Environ === this.state.environ) {
            if (!objs[i].GeneratedFromPiTypeId) {
              if (
                (showRec && this.state.searchTerm === "") ||
                (showRec &&
                  objs[i].Name.toLowerCase().includes(
                    this.state.searchTerm.toLowerCase()
                  )) ||
                (showRec &&
                  objs[i].Environ.toLowerCase().includes(
                    this.state.searchTerm.toLowerCase()
                  ))
              ) {
                transRows.push(
                  <BoxTrans
                    history={this.props.history}
                    id={objs[i].BaseId}
                    name={objs[i].Name}
                    obj={objs[i]}
                    executing={transformer.executingTransformers.includes(
                      objs[i].BaseId
                    )}
                    version={objs[i].Version}
                    environ={objs[i].Environ}
                    handleSelectEdit={this.handleSelectEdit}
                    warnBeforeDelete={this.warnBeforeDelete}
                    selectedForDelete={this.state.selectedForDelete}
                    isDeleting={this.state.isDeleting}
                    deployed={objs[i].Deployed}
                    appId={this.props.match.params.appId}
                    key={i}
                    lastModified={objs[i].LastModified}
                  />
                );
              } else if (
                (showRec && this.state.searchTerm === "") ||
                (showRec &&
                  objs[i].Name.toLowerCase().includes(
                    this.state.searchTerm.toLowerCase()
                  )) ||
                (showRec &&
                  objs[i].Environ.toLowerCase().includes(
                    this.state.searchTerm.toLowerCase()
                  ))
              ) {
                transRows.push({
                  ...objs[i],
                  LastModifiedBy: this.userDetails(objs[i].LastModifiedBy),
                });
              }
            }
          }
          environs[objs[i].Environ] = 1;
        }
      } else {
        const objs = [...project.transformers];
        for (let i = 0; i < objs.length; i++) {
          if (!this.state.environ || objs[i].Environ === this.state.environ) {
            if (!objs[i].GeneratedFromPiTypeId) {
              if (
                (showRec &&
                  !this.state.isListView &&
                  this.state.searchTerm === "") ||
                (showRec &&
                  !this.state.isListView &&
                  objs[i].Name.toLowerCase().includes(
                    this.state.searchTerm.toLowerCase()
                  ))
              ) {
                transRows.push(
                  <BoxTrans
                    history={this.props.history}
                    id={objs[i].BaseId}
                    name={objs[i].Name}
                    obj={objs[i]}
                    executing={transformer.executingTransformers.includes(
                      objs[i].BaseId
                    )}
                    version={objs[i].Version}
                    environ={objs[i].Environ}
                    handleSelectEdit={this.handleSelectEdit}
                    warnBeforeDelete={this.warnBeforeDelete}
                    selectedForDelete={this.state.selectedForDelete}
                    isDeleting={this.state.isDeleting}
                    deployed={objs[i].Deployed}
                    appId={this.props.match.params.appId}
                    key={i}
                    lastModified={objs[i].LastModified}
                  />
                );
              } else if (
                (showRec && this.state.searchTerm === "") ||
                (showRec &&
                  objs[i].Name.toLowerCase().includes(
                    this.state.searchTerm.toLowerCase()
                  )) ||
                (showRec &&
                  objs[i].Environ.toLowerCase().includes(
                    this.state.searchTerm.toLowerCase()
                  ))
              ) {
                transRows.push({
                  ...objs[i],
                  LastModifiedBy: this.userDetails(objs[i].LastModifiedBy),
                });
              }
            }
          }
          environs[objs[i].Environ] = 1;
        }
      }

      const types = {};
      if (project.wizards && project.wizards.length > 0) {
        const objs = [...project.wizards];
        for (let i = 0; i < objs.length; i++) {
          const wiz = main.wizards.find(
            (w) => w.typeId === objs[i].IntegrationTypeId
          );
          if (!this.state.type || wiz.title === this.state.type) {
            if (
              (showWiz && this.state.searchTerm === "") ||
              (showWiz &&
                objs[i].Name.toLowerCase().includes(
                  this.state.searchTerm.toLowerCase()
                ))
            ) {
              transRows.push(
                <BoxWizard
                  history={this.props.history}
                  wiz={wiz}
                  baseId={objs[i].TransformerBaseId}
                  name={objs[i].Name}
                  handleSelectEdit={this.handleSelectEdit}
                  warnBeforeDelete={this.warnBeforeDelete}
                  isDeleting={this.state.isDeleting}
                  obj={objs[i]}
                  typeId={objs[i].IntegrationTypeId}
                  appId={this.props.match.params.appId}
                  piGuid={objs[i].ProductizedIntegrationGuid}
                  key={i + 8000}
                  lastModified={objs[i].LastModified}
                  configs={objs[i].Configuration}
                />
              );
            }
          }
          types[wiz.title] = 1;
        }
      }

      if (transRows) {
        if (!this.state.isListView) { // transRows = transformers          
          if (this.state.sortType < 1) {
            transRows.sort((a, b) => {
              if (a.Name && b.Name) {
                if (a.props.name.toLowerCase() < b.props.name.toLowerCase())
                  return -1;
                else if (a.props.name.toLowerCase() > b.props.name.toLowerCase())
                  return 1;
                return 0;
              }
            });
          } else if (this.state.sortType < 2) {
            transRows.sort((a, b) => {
              if (a.props.lastModified && b.props.lastModified) {
                if (a.props.lastModified < b.props.lastModified) return 1;
                else if (a.props.lastModified > b.props.lastModified) return -1;
                return 0;
              }
            });
          } else {
            transRows.sort((a, b) => {
              if (
                a.props.obj.LanguageVersion === "2.0" &&
                b.props.obj.LanguageVersion === "3.0"
              )
                return -1;
              else if (
                a.props.obj.LanguageVersion === "3.0" &&
                b.props.obj.LanguageVersion === "2.0"
              )
                return 1;
              return 0;
            });
          }
        } else { // transRows = tiles          
          if (this.state.sortType < 1) {
            transRows.sort((a, b) => {
              if (a.Name && b.Name) {
                if (a.Name.toLowerCase() < b.Name.toLowerCase()) return -1;
                else if (a.Name.toLowerCase() > b.Name.toLowerCase()) return 1;
                return 0;
              }
            });
          } else if (this.state.sortType < 2) {
            transRows.sort((a, b) => {
              if (a.LastModified && b.LastModified) {
                if (a.LastModified < b.LastModified) return 1;
                else if (a.LastModified > b.LastModified) return -1;
                return 0;
              }
            });
          }
        }
      }

    }

    if (project && project.transformers && project.transformers.length < 1) {
      transRows = (
        <div className="none-env" style={{ marginTop: -10 }}>
          <span style={{ fontSize: 16 }}>No Recipes.</span>
          <br />
          <Button
            bsSize="small"
            bsStyle="primary"
            style={{ marginTop: 6 }}
            onClick={this.newTransformer}
          >
            New Recipe
          </Button>
        </div>
      );
    } else if (transRows && transRows.length < 1) {
      transRows = (
        <div style={{ textAlign: "center", marginTop: 30 }}>
          <span style={{ margin: "auto", fontSize: 16 }}>No match</span>
        </div>
      );
    }

    const environFilter = [];
    environFilter.push(
      <div key="all" style={{ marginLeft: 18 }}>
        <input
          className="form-control-checkbox"
          defaultChecked
          onClick={this.handleClick}
          type="radio"
          name="environ"
          value="ALL"
        />
        ALL
      </div>
    );

    const rowsPerPage = [];
    const preDefineSets = [10, 20, 50, 100];
    preDefineSets.map((v, i) => {
      rowsPerPage.push(
        <div key={i} style={{ marginLeft: 18 }}>
          <input
            className="form-control-checkbox"
            defaultChecked={this.state.recordsPerPage === v ? true : false}
            onClick={this.handleRowSizeChange}
            type="radio"
            name="records"
            value={v}
          />
          {v}
        </div>
      );
    });

    for (const key in environs) {
      if (environs.hasOwnProperty(key)) {
        environFilter.push(
          <div key={key} style={{ marginLeft: 18, zIndex: 1 }}>
            <input
              className="form-control-checkbox"
              defaultChecked={false}
              onClick={this.handleClick}
              type="radio"
              name="environ"
              value={key}
            />
            {key}
          </div>
        );
      }
    }

    return (
      <div
        className="parent-env"
        style={{ marginTop: 50, paddingTop: 15, paddingBottom: 50 }}
      >
        <div className="main-tab-content">
          <div
            style={{
              margin: "0px 0px 15px 0px",
              fontSize: "20px",
              display: "inline-block",
            }}
          >
            Recipes
          </div>
          {project.transformers && project.transformers.length > 0 && (
            <div className="main-create-btn-env" style={{ marginBottom: 8 }}>
              <Button
                style={{ marginRight: 10 }}
                bsSize="small"
                bsStyle="primary"
                onClick={this.newTransformer}
                disabled={!project.transformers}
              >
                New Recipe
              </Button>

              <div
                style={{
                  position: "absolute",
                  borderRadius: 4,
                  top: 12,
                  right: 0,
                  paddingRight: 40,
                  zIndex: 1,
                  background: "#ddddddb1",
                  verticalAlign: "top",
                }}
              >
                <div style={{ display: "inline-block", marginLeft: 10 }}>
                  <FormGroup controlId="formBasicText">
                    <FormControl
                      type="text"
                      maxLength="50"
                      style={{ width: 200, marginRight: 8 }}
                      autoComplete="off"
                      placeholder="Search"
                      value={this.state.searchTerm}
                      onChange={(e) => this.changeName(e.target.value)}
                    />
                  </FormGroup>
                </div>

                <DropdownButton
                  onToggle={this.onToggleEnviron}
                  open={this.state.environDropDownOpened}
                  onSelect={this.selectEnviron}
                  title="Tag"
                  bsSize="small"
                  bsStyle="default"
                  style={{
                    marginRight: 4,
                    width: 60,
                    height: 31,
                    background: "#ddd",
                    border: "1px solid #666",
                  }}
                  id="environ"
                >
                  {environFilter}
                </DropdownButton>
                <Button
                  bsSize="small"
                  onClick={this.sortTrans}
                  style={{
                    marginRight: 10,
                    background: "#ddd",
                    width: 70,
                    border: "1px solid #666",
                  }}
                >
                  <img
                    src={images.ic_sort_grey600_48dp}
                    style={SettingsStyle.iconStyles}
                    alt="alpha"
                  />
                  {this.state.sortType < 1 ? (
                    <span>Abc</span>
                  ) : this.state.sortType < 2 ? (
                    <span>Date</span>
                  ) : (
                    <span>Date</span>
                  )}
                </Button>

                <Button
                  bsSize="small"
                  onClick={() => {
                    this.setState({ isListView: !this.state.isListView });
                  }}
                  style={{
                    marginRight: 10,
                    background: "#ddd",
                    width: 100,
                    border: "1px solid #666",
                  }}
                >
                  <img
                    src={
                      this.state.isListView
                        ? images.ic_menu
                        : images.ic_list_black_48dp
                    }
                    style={{ ...SettingsStyle.iconStyles, marginRight: 3 }}
                    alt="alpha"
                  />
                  {this.state.isListView ? (
                    <span>Tile View</span>
                  ) : (
                    <span>List View</span>
                  )}
                </Button>
              </div>
            </div>
          )}

          {project.transformers ? (
            this.state.isListView && project.transformers.length > 0 ? (
              <div
                className="recipe-table-wrap"
                style={{
                  marginTop: 10,
                  backgroundColor: "#fff",
                }}
              >
                <ReactTooltip place={"bottom"} className="tool-tip" />
                {transRows && transRows.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      marginBottom: -10,
                      paddingTop: 10,
                      paddingBottom: 2,
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{ width: "50%", paddingTop: 5, fontSize: 15 }}
                    ></div>
                    <div
                      style={{
                        display: "flex",
                        width: "50%",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <div style={{}}>
                        <span>Rows per page:</span>
                        <DropdownButton
                          onToggle={this.onToggleRowsPerPage}
                          open={this.state.rowsPerPageDropDownOpened}
                          onSelect={this.selectRowsPerPage}
                          title={recordsPerPage}
                          bsSize="xsmall"
                          bsStyle="default"
                          style={{
                            marginRight: 4,
                            marginLeft: 10,
                            width: 50,
                          }}
                          id="rowsPerPage"
                        >
                          {rowsPerPage}
                        </DropdownButton>
                      </div>
                      <div style={{ paddingInline: 16 }}>
                        {(activePage - 1) * recordsPerPage + 1}-
                        {activePage ===
                        Math.floor(transRows.length / recordsPerPage) + 1
                          ? transRows.length
                          : (activePage - 1) * recordsPerPage + recordsPerPage}
                        {"  "}of{"  "}
                        {transRows.length}
                      </div>
                      <div style={{ paddingRight: 16 }}>
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={this.state.recordsPerPage}
                          totalItemsCount={
                            transRows && transRows.length ? transRows.length : 0
                          }
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange.bind(this)}
                          activeLinkClass={
                            this.state.rowsPerPageDropDownOpened
                              ? "pagination-active"
                              : "active"
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div style={{ padding: 16 }}>
                  {transRows && transRows.length > 0 ? (
                    <Table responsive condensed={true} bordered>
                      <thead
                        style={{
                          background: "#fff",
                        }}
                      >
                        <tr>
                          <th>Name</th>
                          <th>Last Modified</th>
                          <th>Create Date</th>
                          <th>Deployed</th>
                          <th>Modified By</th>
                          <th>Tag</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transRows &&
                          transRows.length > 0 &&
                          transRows
                            .slice(
                              (activePage - 1) * recordsPerPage,
                              (activePage - 1) * recordsPerPage + recordsPerPage
                            )
                            .map((t, i) => {
                              return (
                                <tr key={t.Id} className="connexp-box">
                                  <td
                                    style={{
                                      textDecoration: "underline",
                                      color: "#337ab7",
                                      fontWeight: "bold",
                                      maxHeight: 38,
                                      overflow: "hidden",
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) =>
                                      this.click(t.appId, t.BaseId)
                                    }
                                  >
                                    {t.Name}
                                  </td>

                                  <td>
                                    {moment(t.LastModified).format(
                                      "MMM Do Y, hh:mm A"
                                    )}
                                  </td>
                                  <td>
                                    {moment(t.CreatedDate).format(
                                      "MMM Do Y, hh:mm A"
                                    )}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {t.Deployed ? "Yes" : "No"}
                                  </td>
                                  <td>{t.LastModifiedBy}</td>
                                  <td>{t.Environ}</td>
                                  <td>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        flexDirection: "row",
                                        justifyContent: "space-evenly",
                                      }}
                                    >
                                      <div
                                        data-tip={"Edit Tag"}
                                        style={{
                                          cursor: "pointer",
                                          marginTop: "6px",
                                          width: "15px",
                                          height: "15px",
                                          textAlign: "left",
                                        }}
                                        onClick={() => {
                                          this.handlePropertiesChange(t);
                                        }}
                                      >
                                        <img
                                          className="delete-experience"
                                          src={images.Tags}
                                          style={{
                                            ...SettingsStyle.iconStyles,
                                          }}
                                          alt="alpha"
                                        />
                                      </div>
                                      <div
                                        data-tip={"Delete"}
                                        style={{
                                          textAlign: "right",
                                          cursor: "pointer",
                                          width: "25px",
                                          height: "25px",
                                        }}
                                      >
                                        <svg
                                          onClick={(e) =>
                                            this.warnBeforeDelete(null, t, e)
                                          }
                                          className="delete-experience"
                                          height="30"
                                          width="30"
                                          viewBox="14 14 50 50"
                                        >
                                          <path
                                            style={{
                                              strokeWidth: 2,
                                              strokeLinecap: "round",
                                            }}
                                            d="M 25.3333,23.75L 50.6667,23.75C 51.5411,23.75 51.8541,27.3125 51.8541,27.3125L 24.1458,27.3125C 24.1458,27.3125 24.4589,23.75 25.3333,23.75 Z M 35.625,19.7917L 40.375,19.7917C 40.8122,19.7917 41.9583,20.9378 41.9583,21.375C 41.9583,21.8122 40.8122,22.9584 40.375,22.9584L 35.625,22.9584C 35.1878,22.9584 34.0416,21.8122 34.0416,21.375C 34.0416,20.9378 35.1878,19.7917 35.625,19.7917 Z M 27.7083,28.5L 48.2916,28.5C 49.1661,28.5 49.875,29.2089 49.875,30.0834L 48.2916,53.8334C 48.2916,54.7078 47.5828,55.4167 46.7083,55.4167L 29.2917,55.4167C 28.4172,55.4167 27.7083,54.7078 27.7083,53.8334L 26.125,30.0834C 26.125,29.2089 26.8339,28.5 27.7083,28.5 Z M 30.0833,31.6667L 30.4792,52.25L 33.25,52.25L 32.8542,31.6667L 30.0833,31.6667 Z M 36.4167,31.6667L 36.4167,52.25L 39.5833,52.25L 39.5833,31.6667L 36.4167,31.6667 Z M 43.1458,31.6667L 42.75,52.25L 45.5208,52.25L 45.9167,31.6667L 43.1458,31.6667 Z "
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                      </tbody>
                    </Table>
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      <span style={{ margin: "auto", fontSize: 16 }}>
                        No match
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div style={{ marginTop: 14 }}>{transRows}</div>
            )
          ) : (
            <div>
              <img alt="spinner" src={images.ajax_loader} />
            </div>
          )}

          <ConfirmDeleteModal
            modalState={this.state.modal}
            triggerModal={this.triggerModal}
            nameOfTarget={
              this.state.selectedForDelete !== null &&
              this.state.selectedForDelete.Name
            }
            deleteTarget={this.deleteRecipe}
          />
          {this.state.showPropertiesModal && (
            <div style={{ width: 1000 }}>
              <Modal
                show={this.state.showPropertiesModal}
                onHide={() =>
                  this.setState({
                    showPropertiesModal: false,
                    currentRecipeId: 0,
                  })
                }
                dialogClassName="recipe-description"
                backdrop="static"
              >
                <Header closeButton>
                  <Title>
                    <strong>{this.state.name}</strong>
                  </Title>
                </Header>
                <Body style={{ margin: 20 }}>
                  <div style={{ display: "flex", gap: 20 }}>
                    <div style={{ flex: 1 }}>
                      <div style={{ display: "flex" }}>
                        <div style={{ marginRight: 25 }}>
                          <label htmlFor="env">Tag</label>
                          <br />
                          <input
                            className="form-control"
                            type="text"
                            onChange={this.changeTagInput}
                            value={this.state.tag}
                            maxLength="15"
                          />
                        </div>
                      </div>

                      <br />
                      <div>
                        <label htmlFor="description">Description</label>
                        <br />
                        <textarea
                          className="form-control"
                          type="text"
                          onChange={this.changeDescriptionInput}
                          value={this.state.description}
                          style={{
                            // width: 500,
                            height: 200,
                            marginBottom: 20,
                          }}
                        />
                      </div>
                    </div>

                    {/* threshold */}
                    <div style={{ flex: 1 }}>
                      <div>
                        <label>Recipe Duration Notification</label>
                        <br />
                        <div style={{ display: "flex", gap: 20 }}>
                          <div>
                            Hours
                            <select
                              name="thresholdHours"
                              id="hours"
                              placeholder="hours"
                              value={this.state.threasholdHours}
                              onChange={(e) => {
                                this.setState({
                                  threasholdHours: e.target.value,
                                });

                                if (e.target.value == 12) {
                                  this.setState({ threasholdMinutes: "00" });
                                }
                              }}
                              className="form-control"
                              style={{ width: 100 }}
                            >
                              {hours.map((value, index) => {
                                return (
                                  <option value={value} key={index}>
                                    {value}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div>
                            Minutes
                            <select
                              name="thresholdMinutes"
                              id="minutes"
                              placeholder="minutes"
                              disabled={this.state.threasholdHours == 12}
                              value={this.state.threasholdMinutes}
                              onChange={(e) =>
                                this.setState({
                                  threasholdMinutes: e.target.value,
                                })
                              }
                              className="form-control"
                              style={{ width: 100 }}
                            >
                              {minutes.map((value, index) => {
                                return (
                                  <option value={value} key={index}>
                                    {value}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div style={{ marginTop: 10 }}>
                        Emails
                        <br />
                        <div style={{ display: "flex" }}>
                          <input
                            className="form-control"
                            type="email"
                            onChange={(e) => {
                              this.setState({ emailText: e.target.value });
                            }}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") this.addEmail();
                            }}
                            value={this.state.emailText}
                          />
                          <Button
                            bsSize="small"
                            bsStyle="primary"
                            style={{ marginLeft: "25px" }}
                            onClick={this.addEmail}
                          >
                            Add Email
                          </Button>
                        </div>
                        {transformer.notificationEmail.validationMessage && (
                          <div style={{ fontSize: "11px", color: "#ea5a5a" }}>
                            {transformer.notificationEmail.validationMessage}
                          </div>
                        )}
                        <div
                          style={{
                            border: "1px solid gray",
                            marginTop: 20,
                            height: 200,
                            overflowY: "scroll",
                          }}
                        >
                          {!transformer.notificationEmail.loading &&
                            transformer.notificationEmail.emails.map(
                              (value, index) => {
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      margin: 0,
                                      padding: 8,
                                      borderBottom: "1px solid lightgray",
                                    }}
                                  >
                                    <div style={{ display: "flex" }}>
                                      {" "}
                                      <Button
                                        bsSize="small"
                                        bsStyle="primary"
                                        onClick={() => this.deletEmail(index)}
                                        style={{
                                          borderRadius: 50,
                                          width: 25,
                                          height: 25,
                                          padding: 3,
                                          border: "1px solid gray",
                                        }}
                                      >
                                        ✖
                                      </Button>
                                      <p style={{ margin: 5 }}>{value}</p>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Body>

                <Footer>
                  <Button
                    bsSize="small"
                    bsStyle="primary"
                    onClick={() =>
                      this.saveTransformer(
                        "properties",
                        this.state.currentRecipeId
                      )
                    }
                    style={{ display: "inline-block" }}
                  >
                    Save
                  </Button>
                </Footer>
              </Modal>
            </div>
          )}
        </div>
      </div>
    );
  }
}
