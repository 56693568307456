import React, { Component } from "react";
import Helmet from "react-helmet";
import WizardFormLoader from "@lingk/sync/build/main";
import * as inputs from "@lingk/sync/build/lingk";
import StartScreen from "./startScreen";
import images from "../images";
import OngoingLogStep from "./ongoingLogResult";
import "@lingk/sync/build/css/main.css";
import "@lingk/sync/build/css/lingkStyles.css";
/* imported wiz css in pages/main ? */
class WizardFormWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wiz: null,
      piGuid: "",
      piIndex: 0,
      started: false,
      completed: false,
      generating: false,
      running: false,
      loadingMessage: "",
      generationError: false,
      updateWizardVersionId: null, // id to update, not create new version
    };
  }

  componentWillMount() {
    const { match, redux, location } = this.props;
    const { params } = match;
    const { wizardID, appId } = params;
    const { state } = location;

    this.setState({ accountId: appId });
    //start from marketplace
    let wizTypeId = wizardID;
    //restart saved wizard
    if (state && state.piGuid) {
      this.setState({
        accountId: appId,
        piGuid: state.piGuid,
        piIndex: state.piIndex,
        started: true,
        updateWizardVersionId: state.updateWizardVersionId,
        transformerBaseId: state.transformerBaseId,
      });
      wizTypeId = state.typeId;
    }

    const wiz =
      redux.main &&
      redux.main.wizards &&
      redux.main.wizards.find((w) => String(wizTypeId) === String(w.typeId));
    if (wiz) {
      this.setState({ wiz });
    } else {
      this.props.history.push("/");
    }
  }

  onUpdateScenarios = ({ typeGuid, scenarios }) => {
    let existingWizard = { ...this.state.wiz };
    existingWizard = { ...existingWizard, scenarios };
    this.setState({ wiz: null });
    setTimeout(() => {
      this.setState({ wiz: existingWizard });
    }, 15);
  };

  componentWillUnmount() {
    const { actions } = this.props;
    actions.clearWizard(); // this function is in transformerActions.js
  }

  submit = (status) => {
    if (status.complete) {
      //actions.setSyncCompleted(true)
      this.setState({
        completed: true,
        generating: true,
        loadingMessage: "Your Integration is Being Generated",
      });
    } else {
      this.setState({
        completed: true,
        loadingMessage: "Your Data Integration Configuration is Saving...",
      });
    }
  };

  piPost = (pi) => {
    const { redux, actions, match } = this.props;
    const { params } = match;
    const { appId } = params;
    actions
      .callGetWizards(redux.project.tenantInfo.TenantId, appId)
      .then(() => {
        this.props.history.push({
          pathname: `/a/${this.props.match.params.appId}/tf`,
        });
      });
  };

  generate = () => {
    this.setState({
      loadingMessage: "Your Data Integration is Executing...",
      running: true,
    });
  };

  finish = (generatedRecipe, pi) => {
    const piGuid = pi.productizedIntegrationGuid;
    const { match, actions, redux } = this.props;
    const { params } = match;
    const { appId, transId } = params;
    // this does not work the first time, as OG pi has no transformerBaseId
    if (generatedRecipe.BaseId) {
      Promise.all([
        actions.callGetTransformerLogs(
          redux.project.tenantInfo.TenantId,
          generatedRecipe.BaseId,
          "",
          100
        ),
        actions.callGetAllTriggers(transId),
        actions.callGetPiConfig(
          redux.project.tenantInfo.TenantId,
          appId,
          piGuid
        ),
        actions.callGetTransformerVersions(appId, transId),
        actions.callGetDeploymentsForTransformer(appId, transId),
      ]).then(() => {
        this.props.history.push({
          pathname: `/a/${appId}/wiz/${generatedRecipe.BaseId}`,
        });
      });
      actions.callGetWizards(redux.project.tenantInfo.TenantId, appId);
    } else {
      actions
        .callGetWizards(redux.project.tenantInfo.TenantId, appId)
        .then(() => {
          this.props.history.push({
            pathname: `/a/${appId}/tf`,
          });
        });
    }
  };

  exitWithoutSaving = () => {
    const { match } = this.props;
    const { params } = match;
    const { appId } = params;
    this.props.history.push({
      pathname: `/a/${appId}/tf`,
    });
  };

  onGenerationError = (errMsg) => {
    this.setState({
      loadingMessage: errMsg,
      generationError: true,
      generating: false,
    });
  };

  render() {
    const { redux } = this.props;
    const { project } = redux;
    if (
      !(
        project &&
        project.tenantInfo &&
        project.tenantInfo.TenantId &&
        this.state.wiz
      )
    )
      return <div />;

    // I HATE THIS! Not sure how to fix it tho
    const configureMode = location.pathname.includes("configure");
    const startMode = location.pathname.includes("start");

    const wrapStyle = configureMode
      ? {
          width: "100%",
          height: "100%",
          position: "relative",
          marginTop: 50,
          marginLeft: 58,
        }
      : {};
    const innerWrapStyle = {
      width: "100%",
      height: "100%",
    };
    if (startMode && this.state.started) {
      innerWrapStyle.height = "calc(100% - 50px)";
      innerWrapStyle.marginTop = 50;
      innerWrapStyle.position = "relative";
    }

    return (
      <div className={configureMode ? null : "parent-env"} style={wrapStyle}>
        <div style={innerWrapStyle}>
          <Helmet title="Wizard" />
          {this.state.started ? (
            <div
              style={{
                marginLeft: 0,
                position: "absolute",
                background: "#dcdfdf",
                width: "95%",
                height: "100%",
              }}
            >
              {this.state.completed ? (
                <div>
                  {this.state.generating ? (
                    <OngoingLogs {...this.props} running={this.state.running} />
                  ) : (
                    <div style={{ padding: 20, background: "#dCdFdF" }}>
                      {this.state.generationError ? (
                        <Alert />
                      ) : (
                        <img src={images.ajax_loader} alt="spinner" />
                      )}
                      &nbsp;&nbsp;&nbsp;
                      <span style={{ verticalAlign: "middle" }}>
                        {this.state.loadingMessage}
                      </span>
                    </div>
                  )}
                </div>
              ) : (
                <WizardFormLoader
                  isOrgAdmin={project.tenantInfo.IdentityIsAdmin}
                  wizardConfig={this.state.wiz}
                  inputs={inputs}
                  onSubmit={this.submit}
                  onPiPost={this.piPost}
                  onGenerate={this.generate}
                  onFinish={this.finish}
                  tenantId={project.tenantInfo.TenantId}
                  tenantKey={project.tenantInfo.Key}
                  tenantName={project.tenantInfo.Name}
                  accountId={this.state.accountId}
                  accountKey={project.appData.uniquekey}
                  piGuid={this.state.piGuid || "_"}
                  piIndex={this.state.piIndex}
                  previewToken={localStorage.getItem("userToken")}
                  exitWithoutSaving={this.exitWithoutSaving}
                  onGenerationError={this.onGenerationError}
                  onUpdateScenarios={this.onUpdateScenarios}
                  updateWizardVersionId={this.state.updateWizardVersionId}
                  transformerBaseId={this.state.transformerBaseId || 0}
                />
              )}
            </div>
          ) : (
            <StartScreen
              wiz={this.state.wiz}
              {...this.props}
              start={() => this.setState({ started: true })}
            />
          )}
        </div>
      </div>
    );
  }
}

const OngoingLogs = (props) => {
  const { wizard } = props.redux;
  const logsToShow = [];
  for (let i = 0; i < wizard.ongoingExecutionLogs.length; i++) {
    const s = wizard.ongoingExecutionLogs[i];
    if (s.completed) {
      logsToShow.push(s);
      if (!s.succeeded) {
        break;
      }
    } else {
      // push the first uncompleted one as well
      logsToShow.push(s);
      break;
    }
  }
  const count = wizard.ongoingExecutionLogCount;
  const areOngoingLogs =
    wizard.ongoingExecutionLogs && wizard.ongoingExecutionLogs.length > 0;
  return (
    <div
      style={{
        background: "#dcdfdf",
        padding: 20,
        border: "1px solid #DDD",
        overflowY: "scroll",
      }}
    >
      <div style={{ marginBottom: 6 }}>
        Execution Steps (you do not have to keep this page open):
      </div>
      <OngoingLogStep
        count={count}
        mod={2}
        step={{
          statement: "Generating Recipe",
          completed: props.running,
          succeeded: props.running,
          sortOrder: -2,
        }}
      />
      {props.running && (
        <OngoingLogStep
          count={count}
          mod={2}
          step={{
            statement: "Starting Recipe",
            completed: areOngoingLogs,
            succeeded: areOngoingLogs,
            sortOrder: -1,
          }}
        />
      )}
      {props.running &&
        logsToShow.map((s, i) => {
          return <OngoingLogStep step={s} key={i} count={count} mod={2} />;
        })}
    </div>
  );
};

const Alert = () => {
  return (
    <svg
      style={{ verticalAlign: "top" }}
      fill="#EA5A5A"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
    </svg>
  );
};

export default WizardFormWrapper;
