import React, { Component, Fragment } from "react";
import EditTransformer from "./edittrans";
import FilesBar from "./filesBar";
import { Route } from "react-router-dom";

class MainEditor extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      prevTransId: 0,
      unSaved: null,
      exploreMeta: false,
    };
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  async componentWillMount() {
    this._isMounted = true;

    const { match, actions, redux, dispatch } = this.props;
    const { project } = redux;
    const { params } = match;
    const { transId, appId } = params;
    this.setState({ prevTransId: transId });
    if (this._isMounted) {
      actions.clearTransformerVersions();
      actions.callGetDeploymentsForTransformer(appId, transId);
      actions.callGetTransformerVersions(appId, transId);
      actions.callGetTransformerContext(transId);
      if (project && project.tenantInfo) {
        actions.callGetAllTriggers(transId);
        if (project && !project.environments) {
          await actions.callGetEnvironments(project.tenantInfo.TenantId, appId);
        }
      }
    }
  }
  componentDidUpdate() {
    this._isMounted = true;

    const { match, actions, redux, dispatch } = this.props;
    const { project } = redux;
    const { params } = match;
    const { transId, appId } = params;
    if (this._isMounted) {
      if (this.state.prevTransId != this.props.match.params.transId) {
        this.setState({ prevTransId: transId, unSaved: null });
        actions.callGetDeploymentsForTransformer(appId, transId);
        actions.callGetTransformerVersions(appId, transId);
        actions.callGetTransformerContext(transId);
        actions.callGetAllTriggers(transId);
      }
    }
  }
  showUnsaved = (r) => {
    this.setState({ unSaved: r });
  };
  render() {
    return (
      <div style={{ display: "flex" }}>
        <Route
          exact
          path="/a/:appId/tf/:transId"
          render={(routerProps) => (
            <Fragment>
              <FilesBar
                {...this.props}
                unSaved={this.state.unSaved}
                handleEMClick={() =>
                  this.setState({ exploreMeta: !this.state.exploreMeta })
                }
                exploreMeta={this.state.exploreMeta}
              />
              <EditTransformer
                {...routerProps}
                {...this.props}
                showUnsaved={this.showUnsaved}
                exploreMeta={this.state.exploreMeta}
              />
            </Fragment>
          )}
        />
      </div>
    );
  }
}
export default MainEditor;
